import AppNavigation from "./navigation/AppNavigation"
import './style.css'

function App() {
  return (
    <>
      <AppNavigation />
    </>
  );
}

export default App;
